<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" centered @cancel="cancel" @ok="confirm">
      <div slot="title">{{form.id ? '编辑客户' : '新增客户' }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
          <a-form-model-item prop="number" label="客户编号">
            <a-input v-model="form.number" allowClear />
          </a-form-model-item>
          <a-form-model-item prop="name" label="客户名称">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item prop="contact" label="联系人">
            <a-input v-model="form.contact" />
          </a-form-model-item>
          <a-form-model-item prop="phone" label="电话">
            <a-input v-model="form.phone" />
          </a-form-model-item>
          <a-form-model-item prop="email" label="邮箱">
            <a-input v-model="form.email" />
          </a-form-model-item>
          <a-form-model-item prop="address" label="地址">
            <a-input v-model="form.address" />
          </a-form-model-item>
          <a-form-model-item prop="remark" label="备注">
            <a-textarea v-model="form.remark" />
          </a-form-model-item>
          <a-form-model-item prop="is_active" label="状态">
            <a-select v-model="form.is_active" style="width: 100%;">
              <a-select-option :value="true">激活</a-select-option>
              <a-select-option :value="false">冻结</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import { clientCreate, clientUpdate, clientNumber } from '@/api/data';
  import { rules } from './rules';

  export default {
    props: ['visible', 'item'],
    model: { prop: 'visible', event: 'cancel' },
    data() {
      return {
        rules,
        loading: false,
        form: {},
      };
    },
    methods: {
      confirm() {
        this.form.id ? this.update() : this.create();
      },
      create() {
        this.$refs.form.validate(valid => {
          if (valid) {
            let formData = { ...this.form };

            this.loading = true;
            clientCreate(formData).then(data => {
              this.$message.success('新增成功');
              this.$parent.items = this.$functions.insertItem(this.$parent.items, data);
              this.cancel();
            }).finally(() => {
              this.loading = false;
            });
          }
        });
      },
      update() {
        this.$refs.form.validate(valid => {
          if (valid) {
            let formData = { ...this.form };

            this.loading = true;
            clientUpdate(formData).then(data => {
              this.$message.success('修改成功');
              this.$parent.items = this.$functions.replaceItem(this.$parent.items, data);
              this.cancel();
            }).finally(() => {
              this.loading = false;
            });
          }
        });
      },
      cancel() {
        this.$emit('cancel', false);
        this.$refs.form.resetFields();
      },
    },
    watch: {
      visible(status) {
        if (status) {
          if (this.item.id) {
            this.form = { ...this.item };
          } else {
            clientNumber().then(data => {
              this.form = { is_active: true, number: data.number };
            })
          }
        }
      },
    }
  }
</script>

<style scoped>
</style>