export const rules = {
  number: [
    { required: true, message: '请输入编号', trigger: 'change' },
    { max: 32, message: '超出最大长度 (32)', trigger: 'change' },
  ],
  name: [
    { required: true, message: '请输入客户名称', trigger: 'change' },
    { max: 64, message: '超出最大长度 (64)', trigger: 'change' },
  ],
  contacts: [{ max: 32, message: '超出最大长度 (32)', trigger: 'change' }],
  phone: [{ max: 32, message: '超出最大长度 (32)', trigger: 'change' }],
  email: [{ max: 256, message: '超出最大长度 (256)', trigger: 'change' }],
  address: [{ max: 256, message: '超出最大长度 (256)', trigger: 'change' }],
  remark: [{ max: 256, message: '超出最大长度 (256)', trigger: 'change' }],
}